import React, { useEffect, useState } from 'react'
import { Block, Delete, Eye, Search } from '../../common/Icons'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import LoadingRow from '../../common/LoadingRow'
import Pagination from '../../common/Pagination'
import Loader from '../Loader'
import Search_input from '../../common/Search_input'


export default function ItineraryManagement() {
    const [all, setAll] = useState([]);
    const [obj, setObj] = useState({});
    const nav = useNavigate()
    const [skip, setSkip] = useState(0)
    const [search, setSearch] = useState("");
    const [mainid, setMainId] = useState("");
    const [load, setLoad] = useState(true);
    const LIMIT = 8

    const [selectedDisableType, setSelectedDisableType] = useState('');
    const [disableReason, setDisableReason] = useState('');


    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.ITINERARIES}?search=${search}&skip=${skip}&limit=${LIMIT}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)
                setLoad(false)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const deleteFunction = async () => {
        try {
            const { data } = await deleteRequest(`${APIS.ITINERARIES}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                // setSkip(0)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    // const disableFunction = async () => {
    //     try {
    //         const updatedStatus = {
    //             status: 5
    //         }
    //         const { data } = await patchRequest(`${APIS.ITINERARIES}/${mainid}`, updatedStatus);
    //         if (!data.status) {
    //             toaster(data.message, false)
    //         } else {
    //             toaster(data.message, true)
    //             // setSkip(0)
    //             fetchData()
    //         }
    //     } catch (err) {
    //         toaster(err.message, false);
    //     }
    // }

    const handleDisableSubmit = async () => {
        try {
            const updatedStatus = {
                status: 5,
                rejectionReason: disableReason
            };
            const { data } = await patchRequest(`${APIS.ITINERARIES}/${mainid}`, updatedStatus);
            if (!data.status) {
                toaster(data.message, false);
            } else {
                toaster(data.message, true);
                fetchData();
            }
        } catch (err) {
            toaster(err.message, false);
        }
    };


    const submitRequest = async (status) => {
        try {
            let id = obj._id
            let updatedStatus = {
                status: status
            }
            const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, updatedStatus);
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * LIMIT)
    }

    const [total, setTotal] = useState(0)

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="top_bar row align-items-center mt-3">
                        <div className="col-lg-6">
                            <div className="pagetitle">
                                Itinerary Management
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-end align-items-center">
                                <input placeholder='Search any destination...' value={search} onChange={(e) => setSearch(e.target.value)} className='search_input' />
                                {/* <Search_input placeholder={'Search here'} value={search} onChange={(e)=>setSearch(e.target.value)} icon={<Search />} className='search_input' /> */}
                                {/* <NavLink to="/itinerary-management/create-itinerary">
                                    <button className="newBtn">Create Itinerary</button>
                                </NavLink> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colSpan="4">
                                        <th>S.No</th>
                                        <th>Destination</th>
                                        <th>Posted By</th>
                                        {/* <th >Created By</th> */}
                                        <th >Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {load ? <LoadingRow /> : all.length > 0
                                        ?
                                        all.map((element, index) => (
                                            <tr key={element._id}>
                                                <td>{index + 1}</td>
                                                <td>{element.destination_address}</td>
                                                <td>{element.firstname + " " + element.lastname}</td>
                                                <td>
                                                    {element.status === 0 ?
                                                        <span className="badge pending">PENDING</span>
                                                        : element.status === 3 ?
                                                            <span className="badge ongoing">LIVE</span>
                                                            : element.status === 4 ?
                                                                <span className="badge cancelled">CANCELLED</span>
                                                                : element.status === 1 ?
                                                                    <span className="badge accepted">ACCEPTED</span>
                                                                    : <span className="badge rejected">REJECTED</span>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="actions d-flex">
                                                        {element.status === 4 ? null :
                                                            <button className='block' onClick={() => nav(element._id)} data-bs-toggle="tooltip" data-bs-placement="top" title="View Details">
                                                                <Eye />
                                                            </button>
                                                        }
                                                        {element.status === 3 ? null :
                                                            <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#deleteBackdrop">
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Delete itinerary">
                                                                    <Delete />
                                                                </div>
                                                            </button>}
                                                        {element.status === 3 ?
                                                            <button className='disable' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#disableBackdrop">
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Disable Itinerary">
                                                                    <Block />
                                                                </div>
                                                            </button> : null}
                                                        {/* <Button className="btn btn-success table-btn" onClick={() => acceptReject(element._id, "Approved")} text={<Tick />} style={{ color: "green", fontWeight: "100", padding: "5px 5px" }} />
                                                {element.status === "Pending" && <Button className="btn btn-danger table-btn" onClick={() => acceptReject(element._id, "Rejected")} text={<Cancel />} style={{ color: "red", fontWeight: "100", padding: "5px 5px" }} />} */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : <NoDataRow />}
                                    {search !== "" ? null :
                                        <>
                                            {total > LIMIT && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={LIMIT} /></td></tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <ConfirmationPopup message={"Do you really want to delete this request ?"} onYes={() => deleteFunction()} /> */}
                </>

            }
            <div className="modal fade" id="deleteBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Do you really want to delete this itinerary?
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={deleteFunction}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="disableBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Do you really want to disable this itinerary?
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={disableFunction}>Yes</button>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="modal fade" id="disableBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Disable Itinerary</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label">Select a reason for disabling:</label>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="disableType"
                                        id="policyViolation"
                                        checked={selectedDisableType === 'policyViolation'}
                                        onChange={() => {
                                            setSelectedDisableType('policyViolation');
                                            setDisableReason('Video does not meet guidelines (low-resolution or insufficient content). Please review guidelines and resubmit.');
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="policyViolation">
                                        Video does not meet guidelines (low-resolution or insufficient content). Please review guidelines and resubmit.
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="disableType"
                                        id="outdatedDetails"
                                        checked={selectedDisableType === 'outdatedDetails'}
                                        onChange={() => {
                                            setSelectedDisableType('outdatedDetails');
                                            setDisableReason('Error on submission, review tour and accommodation and transfer details and resubmit.');
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="outdatedDetails">
                                        Error on submission, review tour and accommodation and transfer details and resubmit.
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="disableType"
                                        id="freeformDisable"
                                        checked={selectedDisableType === 'freeform'}
                                        onChange={() => {
                                            setSelectedDisableType('freeform');
                                            setDisableReason(''); // Clear for custom input
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="freeformDisable">
                                        Other reason (please specify)
                                    </label>
                                </div>
                            </div>

                            {selectedDisableType === 'freeform' && (
                                <div className="mb-3">
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        value={disableReason}
                                        maxLength={2000}
                                        onChange={(e) => setDisableReason(e.target.value)}
                                        placeholder="Enter the reason for disabling..."
                                    ></textarea>
                                    <small>{disableReason.length}/2000 characters</small>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                                onClick={handleDisableSubmit}
                                disabled={!selectedDisableType || (selectedDisableType === 'freeform' && !disableReason.trim())}
                            >
                                Submit Disable
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
