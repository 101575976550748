// Admin\src\components\users\UserManagement.js

import React, { useEffect, useState } from 'react'
import { Block, Calculator, Delete, Eye, Pencil, Search } from '../../common/Icons'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import Pagination from '../../common/Pagination'
import Search_input from '../../common/Search_input'
import moment from 'moment/moment'
import Loader from '../Loader'



export default function UserManagement() {
    const [all, setAll] = useState([]);
    const router = useNavigate()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(8);
    const [load, setLoad] = useState(true);
    const [mainid, setMainId] = useState("");
    const [total, setTotal] = useState(0)
    const [params] = useSearchParams();
    const [obj, setObj] = useState({})
    // console.log(Array.from(params.entries())," thishsishihsih")
    const search = params.get('search')


    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.USER}?search=${search ?? ""}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)
                setLoad(false)

            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * limit)
    }

    const deleteFinance = async (id) => {
        try {
            const { data } = await deleteRequest(`${APIS.USER}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const handleBlock = async () => {
        try {
            const { data } = await patchRequest(`${APIS.BLOCK_UNBLOCK_USER}/${obj._id}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="top_bar row align-items-center mt-3">
                        <div className="col-lg-6">
                            <div className="pagetitle">
                                User Management <br />
                            </div>
                        </div>
                        <div className="col-lg-6 text-end">
                            {/* <Button text="Add Retailer" className="btn btn-success" onClick={() => nav("add")} /> */}
                            <Search_input placeholder={'Search here'} search={search} handleSearch={(e) => router('?search=' + (e.nativeEvent.data === " " ? search + "+" : e.target.value))} icon={<Search />} className='search_input' />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colSpan="4">
                                        <th>S.No</th>
                                        <th>Full Name</th>
                                        <th>Email</th>
                                        <th>Contact No.</th>
                                        <th>Joined Since.</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {all.length > 0
                                        ?
                                        all.map((element, index) => (
                                            <tr key={element._id}>
                                                <td>{index + 1}</td>
                                                {element.firstname === "" ?
                                                    <>
                                                        <td style={{ textTransform: "unset" }}>Not added yet.</td>
                                                        <td style={{ textTransform: "unset" }}></td>
                                                    </>
                                                    :
                                                    <>
                                                        <td style={{ textTransform: "inherit" }}>{element.firstname + " " + element.lastname}</td>
                                                        <td style={{ textTransform: "unset" }}>{element.email}</td>
                                                    </>
                                                }

                                                <td style={{ textTransform: "unset" }}>{element.mobile === "" ? 'Not added yet.' : element.mobile}</td>
                                                <td style={{ textTransform: "unset" }}>{moment(element.createdAt).format("LL")}</td>
                                                <td>
                                                {element.admin_blocked?
                                                <span className="badge rejected">BLOCKED</span>
                                                :
                                                <span className="badge accepted">ACTIVE</span>
                                                }
                                                </td>
                                                <td>
                                                    <div className="actions">
                                                        <button className='view p-2' data-bs-toggle="tooltip" data-bs-placement="top" title="View User">
                                                            <Link className='view' to={'/user-management/' + element._id}>
                                                                <Eye />
                                                            </Link>
                                                        </button>
                                                        {/* <button className='view p-2'>
                                                    <Link className='view' to={'/user-emi-management/' + element._id}>
                                                        <Calculator />
                                                    </Link>
                                                </button> */}
                                                        <button className='block' style={{ color: (element.admin_blocked ? "#0F993E" : "#ff4c27") }} data-bs-toggle="modal" data-bs-target="#blockBackdrop" onClick={() => setObj(element)}>
                                                            <div data-bs-toggle="tooltip" data-bs-placement="top" title={element.admin_blocked ? 'UnBlock User' : 'Block User'}>
                                                                <Block />
                                                            </div>
                                                        </button>
                                                        {/* <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                        <div data-bs-toggle="tooltip" data-bs-placement="top" title="Delete User">
                                                            <Delete />
                                                        </div>
                                                        </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : <NoDataRow />}
                                    {total > limit && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={limit} /></td></tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ConfirmationPopup message={"Do you really want to delete this user ?"} onYes={() => deleteFinance()} />
                </>
            }

            <div className="modal fade" id="blockBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                {obj.admin_blocked ?
                                    ' Are you sure want to Unblock this user?'
                                    :
                                    'Are you sure want to block this user?'
                                }
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleBlock}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
