// const BASE_URL = 'http://localhost:5000'
const BASE_URL = process.env.REACT_APP_BASE_URL

const routes = {
    LOGIN: "/admin/login",
    GET_ADMIN_PROFILE: "/admin/my-profile",
    UPDATE_ADMIN: "/admin/update-profile",

    CHANGE_PASSWORD: "/admin/change-password",
    GET_STATS: "/admin/get-stats",
    GET_DATA: "/admin/get-data",
    ALL_PROPERTIES: "/admin/getAllProperty",
    FORGOT_PASSWORD: "/admin/recoverAccount",
    VERIFY_OTP: "/admin/verifyOtp",
    VERIFY_CHANGE_PASSWORD: "/admin/verify-change-password",
    BLOCK_USER: "/admin/blockUser/",
    GET_DOCUMENTS: "/admin/getdocuments",
    UPDATE_PROFILE: "/admin/update-profile",
    UPDATE_SUBADMIN_PROFILE: "/admin/profile/",
    GET_REASONS: "/admin/getReason/",
    HIDE_SHOW_PROPERTY: "/admin/hideProperty/",
    GET_PAYMENTS: "/admin/get-payments",
    ALL_USERS: "/admin/getAllUsers",
    GENERATE_TOKEN: "/admin/generate-user-token/",
    DOWNLOAD_DOCUMENTS: "/property/downloadDocuments/",
    GET_PRIVACY_POLICY: "/admin/get_content",
    GET_CANCEL_POLICY: "/admin/get_content",

    //content section
    UPDATE_CONTENT: "/admin/update-content",
    UPDATE_CONTENT_TERMS: "/admin/update_terms_conditions",
    GET_TERMS_AND_CONDITIONS: "/admin/getTerms",
    GET_ALL_FAQ: "/admin/faq_listing",
    GET_SINGLE_FAQ: "/admin/get_single_faq/",
    UPDATE_SINGLE_FAQ: "/admin/update_faq",
    DELETE_FAQ: "/admin/delete_faq/",
    CREATE_FAQ: "/admin/add_faq",


    //dashboard section
    DASHBOARD: "/admin/dashboard",

    //user section
    GET_ALL_USERS: "/admin/users",
    BLOCK_UNBLOCK_USER: "/admin/block-unblock",
    USER: "/admin/users",
    EDIT_USER: '/admin/users/',

    SETTINGS: "/admin/settings",

    // itinerary section
    ITINERARIES: "/admin/itineraries",
    CREATE_ITINERARY: "/admin/create_itineraries",
    DELETE_ITINERARY: "/admin/itineraries",

    //booking & payment section
    GET_BOOKINGS: '/admin/get_booking',
    GET_BOOKING_DETAILS: '/admin/get_booking/',
    GET_PAYMENTS: '/admin/get_payment_list',
    GET_PAYMENT_DETAILS: 'admin/get_payment_details/',

    //rating & reviews
    GET_RATING: '/admin/get_ratings',

    //group section
    GET_GROUPS: '/admin/get_all_groups',
    GET_GROUP_DETAILS: '/admin/get_group_details/',

    //invite section
    GET_INVITES: '/admin/invites',
    SEND_INVITE: '/admin/generate-invite',
}

export default routes;
export { BASE_URL }