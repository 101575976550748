// Admin\src\components\invite\sendInvite.js

import React, { useState, useEffect } from 'react';
import { getRequest, postRequest } from '../../apis/functions';
import { toaster } from '../../apis/commonFunctions';
import Input from '../../common/Input';
import toast from 'react-hot-toast';
import APIS from '../../apis/routes';

const InviteUser = () => {
    const [inviteData, setInviteData] = useState({ email: "", mobile: "" });
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeField, setActiveField] = useState(null);

    function handleChanges(e) {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        setInviteData(prev => ({ ...prev, [name]: trimmedValue }));
        if (name === 'email' || name === 'mobile') {
            setSearchQuery(trimmedValue);
        }
    }

    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.USER}?search=${searchQuery}&skip=0&limit=5`);
            if (!result.data.status) {
                toaster(result.data.message, false);
            } else {
                setUsers(result.data.data);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery) {
                fetchData();
            } else {
                setUsers([]);
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]);

    async function sendInvite() {
        if (!inviteData.email && !inviteData.mobile) {
            toast.error("Please provide either an email or a mobile number");
            return;
        }

        setLoading(true);
        try {
            const result = await postRequest(APIS.SEND_INVITE, inviteData);
            if (!result.data.status) {
                toast.error(result.data.message);
            } else {
                toast.success(result.data.message);
                setInviteData({ email: "", mobile: "" });
                setUsers([]);
            }
        } catch (err) {
            toaster(err.message, false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="pagetitle mb-5 mt-3 w-100" style={{ width: "fit-content" }}>
                <div className='w-100 d-flex justify-content-between'>
                    <div>Send Invite</div>
                </div>
            </div>
            <div className="outer_div mt-2">
                <div className='row'>
                    <div style={{ height: '49px' }}></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className="profile_list p-0 row custom-css">
                            <div className="col-12" style={{ position: 'relative' }}>
                                {/* Email Field */}
                                <li>
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Email Address</div>
                                        <div className="name mt-2">
                                            <Input
                                                type="email"
                                                placeholder="Enter email"
                                                value={inviteData.email}
                                                onChange={handleChanges}
                                                name="email"
                                                onFocus={() => setActiveField('email')}
                                            />
                                        </div>
                                    </div>
                                </li>

                                {/* OR Divider */}
                                <div className="text-center my-3 fw-bold or-divider">OR</div>

                                {/* Mobile Field */}
                                <li>
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Mobile Number</div>
                                        <div className="name mt-2">
                                            <Input
                                                type="tel"
                                                placeholder="Enter mobile number"
                                                value={inviteData.mobile}
                                                onChange={handleChanges}
                                                name="mobile"
                                                onFocus={() => setActiveField('mobile')}
                                            />
                                        </div>
                                    </div>
                                </li>

                                {/* Dropdown for search results */}
                                {users.length > 0 && (
                                    <div 
                                        className="dropdown-results"
                                        style={{
                                            position: 'absolute',
                                            top: activeField === 'email' ? '70px' : '220px',
                                            left: 0,
                                            right: 0,
                                            backgroundColor: '#fff',
                                            border: '1px solid #ddd',
                                            borderRadius: '4px',
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            zIndex: 1000,
                                            marginTop: '5px'
                                        }}
                                    >
                                        {users.map(user => (
                                            <div
                                                key={user._id}
                                                style={{ padding: '8px 12px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                                                onClick={() => {
                                                    setInviteData({ 
                                                        email: user.email || "", 
                                                        mobile: user.mobile || "" 
                                                    });
                                                    setUsers([]);
                                                }}
                                            >
                                                {user.email && <div>Email: {user.email}</div>}
                                                {user.mobile && <div>Mobile: {user.mobile}</div>}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Submit Button */}
                                <li className="mt-4">
                                    <div className="detail_wrapper d-flex justify-content-end">
                                        <button
                                            className='btn btn-primary'
                                            style={{ minWidth: "120px", height: "40px" }}
                                            onClick={sendInvite}
                                            disabled={loading || (!inviteData.email && !inviteData.mobile)}
                                        >
                                            {loading ? 'Sending...' : 'Send Invite'}
                                        </button>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InviteUser;


// // Admin\src\components\invite\sendInvite.js

// import React, { useState, useRef, useEffect } from 'react'; // Import useRef and useEffect
// import { getRequest, postRequest } from '../../apis/functions';
// import { toaster } from '../../apis/commonFunctions';
// import Input from '../../common/Input';
// import toast from 'react-hot-toast';
// import APIS from '../../apis/routes';
// import './sendInvite.css'; // Import CSS for styling

// const InviteUser = () => {
//     const [inviteData, setInviteData] = useState({ email: "", mobile: "" });
//     const [loading, setLoading] = useState(false);
//     const [userOptions, setUserOptions] = useState([]); // For user dropdown options
//     const [searchTerm, setSearchTerm] = useState(""); // For search input
//     const [showDropdown, setShowDropdown] = useState(false); // Control dropdown visibility
//     const dropdownRef = useRef(null); // Ref for dropdown element

//     function handleChanges(e) {
//         setInviteData({
//             ...inviteData,
//             [e.target.name]: e.target.value.trim()
//         });
//     }

//     const fetchUsers = async (searchQuery) => { // Function to fetch users based on search
//         if (!searchQuery) {
//             setUserOptions([]); // Clear options if search is empty
//             return;
//         }
//         try {
//             const result = await getRequest(`${APIS.USER}?search=${searchQuery}`);
//             if (!result.data.status) {
//                 toaster(result.data.message, false);
//                 setUserOptions([]); // Clear options on error
//             } else {
//                 setUserOptions(result.data.data);
//             }
//         } catch (err) {
//             toaster(err.message, false);
//             setUserOptions([]); // Clear options on error
//         }
//     };

//     const handleSearchInputChange = (e) => {
//         const value = e.target.value;
//         setSearchTerm(value);
//         setShowDropdown(true); // Show dropdown on input change
//         fetchUsers(value); // Fetch users based on input
//     };

//     async function sendInvite() {
//         if (!inviteData.email && !inviteData.mobile) {
//             toast.error("Please provide either an email or a mobile number");
//             return;
//         }

//         setLoading(true);
//         try {
//             const result = await postRequest(APIS.SEND_INVITE, inviteData);
//             if (!result.data.status) {
//                 toast.error(result.data.message);
//             } else {
//                 toast.success(result.data.message);
//                 setInviteData({ email: "", mobile: "" });
//                 setUserOptions([]); // Clear user options after invite
//                 setSearchTerm(""); // Clear search term after invite
//                 setShowDropdown(false); // Hide dropdown after invite
//             }
//         } catch (err) {
//             toaster(err.message, false);
//         } finally {
//             setLoading(false);
//         }
//     }

//     const handleUserSelection = (user) => {
//         setInviteData({
//             email: user.email || "",
//             mobile: user.mobile || ""
//         });
//         setSearchTerm(""); // Clear search term
//         setUserOptions([]); // Clear dropdown options
//         setShowDropdown(false); // Hide dropdown
//     };

//     // Function to close dropdown on outside click
//     const handleClickOutsideDropdown = (event) => {
//         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//             setShowDropdown(false);
//         }
//     };

//     useEffect(() => {
//         document.addEventListener("mousedown", handleClickOutsideDropdown);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutsideDropdown);
//         };
//     }, []);


//     return (
//         <>
//             <div className="pagetitle mb-5 mt-3 w-100" style={{ width: "fit-content" }}>
//                 <div className='w-100 d-flex justify-content-between'>
//                     <div>
//                         Send Invite
//                     </div>
//                 </div>
//             </div>
//             <div className="outer_div mt-2">
//                 <div className='row'>
//                     <div style={{ height: '49px' }}></div>
//                 </div>
//                 <div className="row">
//                     <div className="col-12">
//                         <ul className="profile_list p-0 row custom-css">
//                             <div className="col-12">
//                                 {/* Email Field */}
//                                 <li>
//                                     <div className="detail_wrapper">
//                                         <div className="small_heading">Email Address</div>
//                                         <div className="name mt-2 position-relative"> {/* Make position relative for dropdown positioning */}
//                                             <Input
//                                                 type="email"
//                                                 placeholder="Enter email"
//                                                 value={searchTerm} // Use searchTerm for input value
//                                                 onChange={handleSearchInputChange} // Use handleSearchInputChange for search
//                                                 name="email"
//                                                 onFocus={() => setShowDropdown(true)} // Show dropdown on focus
//                                             // onBlur={() => setShowDropdown(false)} // Hide dropdown on blur - handled by outside click now
//                                             />
//                                             {showDropdown && userOptions.length > 0 && (
//                                                 <ul ref={dropdownRef} className="user-dropdown" > {/* Use user-dropdown class and ref */}
//                                                     {userOptions.map(user => (
//                                                         <li key={user._id} onClick={() => handleUserSelection(user)} className="dropdown-item">
//                                                             {user.firstname} {user.lastname} ({user.email || user.mobile})
//                                                         </li>
//                                                     ))}
//                                                 </ul>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </li>

//                                 {/* OR Divider */}
//                                 <div className="text-center my-3 fw-bold or-divider">OR</div>

//                                 {/* Mobile Field */}
//                                 <li>
//                                     <div className="detail_wrapper">
//                                         <div className="small_heading">Mobile Number</div>
//                                         <div className="name mt-2 position-relative"> {/* Make position relative for dropdown positioning */}
//                                             <Input
//                                                 type="tel"
//                                                 placeholder="Enter mobile number"
//                                                 value={inviteData.mobile}
//                                                 onChange={handleChanges}
//                                                 name="mobile"
//                                             />
//                                             {/* We can add dropdown for mobile as well if needed, similar to email */}
//                                         </div>
//                                     </div>
//                                 </li>

//                                 {/* Submit Button */}
//                                 <li className="mt-4">
//                                     <div className="detail_wrapper d-flex justify-content-end">
//                                         <button
//                                             className='btn btn-primary'
//                                             style={{
//                                                 minWidth: "120px",
//                                                 height: "40px",
//                                                 width: "auto",
//                                                 maxWidth: "300px"
//                                             }}
//                                             onClick={sendInvite}
//                                             disabled={loading || (!inviteData.email && !inviteData.mobile)}
//                                         >
//                                             {loading ? 'Sending...' : 'Send Invite'}
//                                         </button>
//                                     </div>
//                                 </li>
//                             </div>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default InviteUser;



// // Admin\src\components\invite\sendInvite.js

// import React, { useState } from 'react';
// import { getRequest, postRequest } from '../../apis/functions';
// import { toaster } from '../../apis/commonFunctions';
// import Input from '../../common/Input';
// import toast from 'react-hot-toast';
// import APIS from '../../apis/routes';

// const InviteUser = () => {
//     const [inviteData, setInviteData] = useState({ email: "", mobile: "" });
//     const [loading, setLoading] = useState(false);
//     const [users, setUsers] = useState([]);

//     function handleChanges(e) {
//         setInviteData({
//             ...inviteData,
//             [e.target.name]: e.target.value.trim()
//         });
//     }

//     const fetchData = async () => {
//         try {
//             const result = await getRequest(`${APIS.USER}?search=${search ?? ""}&skip=${skip}&limit=${limit}`);
//             if (!result.data.status) {
//                 toaster(result.data.message, false)
//             } else {
//                 setUsers(result.data.data)
//             }
//         } catch (err) {
//             toaster(err.message, false);
//         }
//     }

//     async function sendInvite() {
//         if (!inviteData.email && !inviteData.mobile) {
//             toast.error("Please provide either an email or a mobile number");
//             return;
//         }

//         setLoading(true);
//         try {
//             const result = await postRequest(APIS.SEND_INVITE, inviteData);
//             if (!result.data.status) {
//                 toast.error(result.data.message);
//             } else {
//                 toast.success(result.data.message);
//                 setInviteData({ email: "", mobile: "" });
//             }
//         } catch (err) {
//             toaster(err.message, false);
//         } finally {
//             setLoading(false);
//         }
//     }

//     return (
//         <>
//             <div className="pagetitle mb-5 mt-3 w-100" style={{ width: "fit-content" }}>
//                 <div className='w-100 d-flex justify-content-between'>
//                     <div>
//                         Send Invite
//                     </div>
//                 </div>
//             </div>
//             <div className="outer_div mt-2">
//                 <div className='row'>
//                     <div style={{ height: '49px' }}></div>
//                 </div>
//                 <div className="row">
//                     <div className="col-12">
//                         <ul className="profile_list p-0 row custom-css">
//                             <div className="col-12">
//                                 {/* Email Field */}
//                                 <li>
//                                     <div className="detail_wrapper">
//                                         <div className="small_heading">Email Address</div>
//                                         <div className="name mt-2">
//                                             <Input
//                                                 type="email"
//                                                 placeholder="Enter email"
//                                                 value={inviteData.email}
//                                                 onChange={handleChanges}
//                                                 name="email"
//                                             />
//                                         </div>
//                                     </div>
//                                 </li>

//                                 {/* OR Divider */}
//                                 <div className="text-center my-3 fw-bold or-divider">OR</div>

//                                 {/* Mobile Field */}
//                                 <li>
//                                     <div className="detail_wrapper">
//                                         <div className="small_heading">Mobile Number</div>
//                                         <div className="name mt-2">
//                                             <Input
//                                                 type="tel"
//                                                 placeholder="Enter mobile number"
//                                                 value={inviteData.mobile}
//                                                 onChange={handleChanges}
//                                                 name="mobile"
//                                             />
//                                         </div>
//                                     </div>
//                                 </li>

//                                 {/* Submit Button */}
//                                 <li className="mt-4">
//                                     <div className="detail_wrapper d-flex justify-content-end">
//                                         <button
//                                             className='btn btn-primary'
//                                             style={{
//                                                 minWidth: "120px",
//                                                 height: "40px",
//                                                 width: "auto",
//                                                 maxWidth: "300px"
//                                             }}
//                                             onClick={sendInvite}
//                                             disabled={loading || (!inviteData.email && !inviteData.mobile)}
//                                         >
//                                             {loading ? 'Sending...' : 'Send Invite'}
//                                         </button>
//                                     </div>
//                                 </li>
//                             </div>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default InviteUser;