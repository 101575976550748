import './App.css';
import './Mobile.css'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Protected from './guards/Protected';
import Authentic from './guards/Authentic';
import Login_Signup from './components/admin/Login_Signup';
import ForgotPassword from './components/admin/ForgotPassword';
import Dashboard from './components/home/Dashboard';
import PrivacyPolicy from './components/content/PrivacyPolicy';
import TermsCondition from './components/content/TermsCondition';
import AdminProfile from './components/admin/AdminProfile';
import ChangePassword from './components/admin/ChangePassword';
import PageNotFound from './common/PageNotFound';
import { useDispatch } from 'react-redux';
import { getRequest, setRouter } from './apis/functions';
import APIS from './apis/routes';
import { useEffect } from 'react';
import { setDetails } from './redux/slices/detailsSlice';
import UserManagement from './components/users/UserManagement';
import UserDetails from './components/users/UserDetails';
import Settings from './components/admin/Settings';
import ItineraryManagement from './components/itinerary/ItineraryManagement';
import ItineraryDetails from './components/itinerary/ItineraryDetails';
import Sidebar from './common/Sidebar';
import Header from './common/Header';
import CreateItinerary from './components/itinerary/CreateItinerary';
import Booking from './components/bookings/Booking';
import BookingDetails from './components/bookings/BookingDetails';
import Payments from './components/bookings/Payments';
import Rating from './components/rating/Rating';
import Groups from './components/group/Groups';
import GroupDetails from './components/group/GroupDetails';
import CancelationPolicy from './components/content/CancelationPolicy';
import Error from './common/Error';
import PaymentDetails from './components/bookings/PaymentDetails';
import InviteManagement from './components/invite/InviteManagement';
import InviteUser from './components/invite/sendInvite';


function App() {
  const router = useNavigate();
  const location = useLocation()
  const dispatcher = useDispatch()
  setRouter(router);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRequest(APIS.GET_ADMIN_PROFILE);
        let info = JSON.parse(localStorage.getItem('info'))
        info.read = data.data.read
        localStorage.setItem('info', JSON.stringify(info))
        if (data.status) {
          dispatcher(setDetails(data.data))
        }
        // router("/login")
      } catch (err) {
      }
    })()
  }, [])

  var auth = localStorage.getItem("x_token")


  return (
    <>
      {auth ?
        <>
          <Sidebar />
          <main className='main' id='main'>
            <section className='section'>
              <div className="row m-0">
                <div className="col-lg-12 col-md-12 ps-0 pe-0 setBorder">
                  <Header />
                  <div className="all_comp">
                    <Routes>
                      <Route element={<Protected />}>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/cancellation-policy' element={<CancelationPolicy />} />
                        <Route path='/terms-and-condition' element={<TermsCondition />} />
                        <Route path='/user-management' element={<UserManagement />} />
                        <Route path='/user-management/:id' element={<UserDetails />} />
                        <Route path='/itinerary-management' element={<ItineraryManagement />} />
                        <Route path='/payments' element={<Payments />} />
                        <Route path='/payments/:id' element={<PaymentDetails />} />
                        <Route path='/itinerary-management/:id' element={<ItineraryDetails />} />
                        <Route path='/itinerary-management/create-itinerary' element={<CreateItinerary />} />
                        <Route path='/bookings' element={<Booking />} />
                        <Route path='/groups' element={<Groups />} />
                        <Route path='/groups/:id' element={<GroupDetails />} />
                        <Route path='/error' element={<Error />} />
                        <Route path='/ratings-reviews' element={<Rating />} />
                        <Route path='/bookings/:id' element={<BookingDetails />} />
                        <Route path='/admin/profile' element={<AdminProfile />} />
                        <Route path='/admin/change-password' element={<ChangePassword />} />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/invite-management' element={<InviteManagement />} />
                        <Route path='/add-invite' element={<InviteUser />} />
                      </Route>
                      <Route path="*" element={<PageNotFound />} />
                      <Route element={<Authentic />}>
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                      </Route>
                    </Routes>
                  </div>
                </div>
              </div>
            </section>
          </main >
        </>
        :
        <>
          <Routes>
            {/* <Route path="*" element={<PageNotFound />} /> */}
            <Route element={<Authentic />}>
              <Route path='/forgot-password' element={<ForgotPassword />} />
            </Route>
            <Route path='/' element={<Login_Signup />} />
          </Routes>
        </>
      }


    </>
  );
}

export default App;