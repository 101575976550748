import toast from 'react-hot-toast'

const toaster = (message = "", status = true) => {
    if (status) {
        toast.success(message);
    } else {
        toast.error(message)
    }
}

export { toaster }

// Add this in your common functions file
export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
};