// Admin\src\components\invite\InviteManagement.js

import React, { useEffect, useState } from 'react';
import { Search } from '../../common/Icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toaster } from '../../apis/commonFunctions';
import { getRequest, postRequest } from '../../apis/functions';
import APIS from '../../apis/routes';
import NoDataRow from '../../common/NoDataRow';
import Pagination from '../../common/Pagination';
import Search_input from '../../common/Search_input';
import Loader from '../Loader';
import Input from '../../common/Input';
import toast from 'react-hot-toast';

export default function InviteManagement() {
    const [invites, setInvites] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [inviteData, setInviteData] = useState({ email: "", mobile: "" });
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeField, setActiveField] = useState(null);
    const router = useNavigate();
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(8);
    const [load, setLoad] = useState(true);
    const [total, setTotal] = useState(0);
    const [params] = useSearchParams();
    const search = params.get('search');
    const [contactType, setContactType] = useState('email');

    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.GET_INVITES}?search=${search ?? ""}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false);
            } else {
                setInvites(result.data.data);
                setTotal(result.data.total_count);
                setLoad(false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    };

    const handleChanges = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        setInviteData(prev => ({ ...prev, [name]: trimmedValue }));
        setSearchQuery(trimmedValue);
    };

    const fetchUsers = async () => {
        try {
            const result = await getRequest(`${APIS.USER}?search=${searchQuery}&skip=0&limit=5`);
            if (!result.data.status) {
                toaster(result.data.message, false);
            } else {
                setUsers(result.data.data);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery) {
                fetchUsers();
            } else {
                setUsers([]);
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]);

    const sendInvite = async () => {
        if (!inviteData.email && !inviteData.mobile) {
            toast.error("Please provide either an email or a mobile number");
            return;
        }

        setLoading(true);
        try {
            const result = await postRequest(APIS.SEND_INVITE, inviteData);
            if (!result.data.status) {
                toast.error(result.data.message);
            } else {
                toast.success(result.data.message);
                setInviteData({ email: "", mobile: "" });
                setUsers([]);
                setShowModal(false);
                fetchData();
            }
        } catch (err) {
            toaster(err.message, false);
        } finally {
            setLoading(false);
        }
    };

    const handlePageClick = ({ selected }) => {
        setSkip(selected * limit);
    };

    useEffect(() => {
        fetchData();
    }, [search, skip]);

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Invite Management <br />
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                    <button
                        className="btn btn-primary me-3"
                        onClick={() => {
                            setShowModal(true);
                            setContactType('email');
                            setInviteData({ email: "", mobile: "" });
                        }}
                    >
                        Send Invite
                    </button>
                    <Search_input
                        placeholder={'Search here'}
                        search={search}
                        handleSearch={(e) => router('?search=' + (e.nativeEvent.data === " " ? search + "+" : e.target.value))}
                        icon={<Search />}
                        className='search_input'
                    />
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        setShowModal(false);
                    }
                }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Send Invite</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Type</div>
                                            <select
                                                className="form-select mt-2"
                                                value={contactType}
                                                onChange={(e) => setContactType(e.target.value)}
                                            >
                                                <option value="email">Email</option>
                                                <option value="mobile">Mobile</option>
                                            </select>
                                        </div>

                                        <div className="detail_wrapper mt-3">
                                            <div className="small_heading">
                                                {contactType === 'email' ? 'Email Address' : 'Mobile Number'}
                                            </div>
                                            <div className="name mt-2">
                                                <Input
                                                    type={contactType === 'email' ? 'email' : 'tel'}
                                                    placeholder={contactType === 'email' ? 'Enter email' : 'Enter mobile number'}
                                                    value={inviteData[contactType]}
                                                    onChange={handleChanges}
                                                    name={contactType}
                                                    onFocus={() => setActiveField(contactType)}
                                                />
                                            </div>
                                        </div>
                                        {users.length > 0 && (
                                            <div
                                                className="dropdown-results"
                                                style={{
                                                    position: 'relative',
                                                    backgroundColor: '#fff',
                                                    border: '1px solid #ddd',
                                                    borderRadius: '4px',
                                                    maxHeight: '200px',
                                                    overflowY: 'auto',
                                                    zIndex: 1000,
                                                    marginTop: '5px',
                                                    padding: 0
                                                }}
                                            >
                                                {users.map((user, index) => {
                                                    const suggestion =
                                                        contactType === 'email' ? user.email : user.mobile;
                                                    if (!suggestion) return null;
                                                    return (
                                                        <div
                                                            key={user._id}
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: '6px 10px',
                                                                borderBottom:
                                                                    index !== users.length - 1 ? '1px solid #ddd' : 'none'
                                                            }}
                                                            onClick={() => {
                                                                setInviteData(prev => ({
                                                                    ...prev,
                                                                    [contactType]: suggestion
                                                                }));
                                                                setUsers([]);
                                                            }}
                                                        >
                                                            {contactType === 'email'
                                                                ? `Email: ${suggestion}`
                                                                : `Mobile: ${suggestion}`}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={sendInvite}
                                    disabled={loading || (!inviteData.email && !inviteData.mobile)}
                                >
                                    {loading ? 'Sending...' : 'Send Invite'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="row mt-4">
                {load ? (
                    <Loader />
                ) : (
                    <div className="table-responsive">
                        <table className='table table-lg'>
                            <thead className='table-light'>
                                <tr>
                                    <th>S.No</th>
                                    <th>Email</th>
                                    <th>Mobile No.</th>
                                    <th>Invite Code</th>
                                    <th>Created At</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invites.length > 0 ? (
                                    invites.map((invite, index) => (
                                        <tr key={invite._id}>
                                            <td>{index + 1}</td>
                                            <td style={{ textTransform: "unset" }}>
                                                {invite.email ? invite.email : invite.registeredUserId?.email || 'Not added yet.'}
                                            </td>
                                            <td style={{ textTransform: "unset" }}>{invite.mobile ? invite.mobile : invite.registeredUserId?.mobile || 'Not added yet.'}</td>
                                            <td style={{ textTransform: "unset" }}>{invite.code}</td>
                                            <td style={{ textTransform: "unset" }}>
                                                {new Date(invite.createdAt).toLocaleDateString()}
                                            </td>
                                            <td>
                                                {invite.isUsed ?
                                                    <span className="badge accepted">USED</span> :
                                                    <span className="badge rejected">NOT USED</span>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                ) : <NoDataRow />}
                                {total > limit && (
                                    <tr className='text-center'>
                                        <td colSpan={20}>
                                            <Pagination
                                                handlePageClick={handlePageClick}
                                                totalCount={total}
                                                limit={limit}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    );
}


// // Admin\src\components\invite\InviteManagement.js

// import React, { useEffect, useState } from 'react'
// import { Search } from '../../common/Icons'
// import { Link, useNavigate, useSearchParams } from 'react-router-dom'
// import { toaster } from '../../apis/commonFunctions'
// import { getRequest } from '../../apis/functions'
// import APIS from '../../apis/routes'
// import NoDataRow from '../../common/NoDataRow'
// import Pagination from '../../common/Pagination'
// import Search_input from '../../common/Search_input'
// import Loader from '../Loader'


// export default function InviteManagement() {
//     const [invites, setInvites] = useState([]);
//     const router = useNavigate()
//     const [skip, setSkip] = useState(0)
//     const [limit, setLimit] = useState(8);
//     const [load, setLoad] = useState(true);
//     const [total, setTotal] = useState(0)
//     const [params] = useSearchParams();
//     const search = params.get('search')


//     const fetchData = async () => {
//         try {
//             const result = await getRequest(`${APIS.GET_INVITES}?search=${search ?? ""}&skip=${skip}&limit=${limit}`);
//             console.log('result from admin/invites : ', result)
//             if (!result.data.status) {
//                 toaster(result.data.message, false)
//             } else {
//                 setInvites(result.data.data)
//                 setTotal(result.data.total_count)
//                 setLoad(false)

//             }
//         } catch (err) {
//             toaster(err.message, false);
//         }
//     }

//     function handlePageClick({ selected }) {
//         setSkip(selected * limit)
//     }

//     useEffect(() => {
//         fetchData();
//     }, [search, skip])

//     return (
//         <>
//             {load ?
//                 <Loader />
//                 :
//                 <>
//                     <div className="top_bar row align-items-center mt-3">
//                         <div className="col-lg-6">
//                             <div className="pagetitle">
//                                 Invite Management <br />
//                             </div>
//                         </div>
//                         <div className="col-lg-6 text-end">
//                             <Search_input placeholder={'Search here'} search={search} handleSearch={(e) => router('?search=' + (e.nativeEvent.data === " " ? search + "+" : e.target.value))} icon={<Search />} className='search_input' />
//                         </div>
//                         <div className="col-lg-6">
//                             <Link type="button" to={'/add-invite'} className="btn btn-primary">Send Invite</Link>
//                         </div>
//                     </div>
//                     <div className="row mt-4">
//                         <div className="table-responsive">
//                             <table className='table table-lg'>
//                                 <thead className='table-light'>
//                                     <tr>
//                                         <th>S.No</th>
//                                         <th>Email</th>
//                                         <th>Mobile No.</th>
//                                         <th>Invite Code</th>
//                                         <th>Status</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {invites.length > 0
//                                         ?
//                                         invites.map((invite, index) => (
//                                             <tr key={invite._id}>
//                                                 <td>{index + 1}</td>
//                                                 <td style={{ textTransform: "unset" }}>
//                                                     {invite.email ? invite.email : invite.registeredUserId?.email || 'Not added yet.'}
//                                                 </td>
//                                                 <td style={{ textTransform: "unset" }}>{invite.mobile ? invite.mobile : invite.registeredUserId?.mobile || 'Not added yet.'}</td>
//                                                 <td style={{ textTransform: "unset" }}>{invite.code}</td>
//                                                 <td>
//                                                     {invite.isUsed ?
//                                                         <span className="badge accepted">USED</span>
//                                                         :
//                                                         <span className="badge rejected">NOT USED</span>
//                                                     }
//                                                 </td>
//                                             </tr>
//                                         ))
//                                         : <NoDataRow />}
//                                     {total > limit && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={limit} /></td></tr>}

//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </>
//             }
//         </>
//     )
// }
